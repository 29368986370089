<template>
  <!-- 企业会计制度 利润表-->
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h5>{{title}}</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:31%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:13%;">第一季度</td>
          <td class="center" style="width:13%;">第二季度</td>
          <td class="center" style="width:13%;">第三季度</td>
          <td class="center" style="width:13%;">第四季度</td>
          <!-- <td class="center" style="width:13%;">本年累计</td> -->
        </tr>
      </table>
      <div class="fixed_box_table" :style="{height:contentStyleObj}">
        <table class="content" cellspacing="0">
          <tr>
            <td class="weight" style="width:31%;">一、主营业务收入</td>
            <td class="center" style="width:4%;">1</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[0].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[0].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[0].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[0].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_k">减：主营业务成本</td>
            <td class="center">2</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[1].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[1].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[1].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[1].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_b">主营业务税⾦及附加</td>
            <td class="center">3</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[2].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[2].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[2].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[2].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight">⼆、主营业务利润（亏损以“－”号填列）(1-2-3)</td>
            <td class="center">4</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[3].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[3].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[3].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[3].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_k">加：其他业务利润（亏损以“－”号填列</td>
            <td class="center">5</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[4].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[4].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[4].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[4].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>

          <tr>
            <td class="left_k">减：营业费⽤</td>
            <td class="center">6</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[5].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[5].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[5].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[5].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>

          <tr>
            <td class="left_b">管理费⽤</td>
            <td class="center">7</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[6].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[6].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[6].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[6].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_b">财务费⽤</td>
            <td class="center">8</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[7].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[7].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[7].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[7].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight">三、营业利润（亏损以“－”号填列）(4+5-6-7-8)</td>
            <td class="center">9</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[8].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[8].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[8].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[8].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_k">加：投资收益（损失以“－”号填列）</td>
            <td class="center">10</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[9].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[9].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[9].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[9].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_b">补贴收⼊</td>
            <td class="center">11</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[10].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[10].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[10].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[10].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_b">营业外收⼊</td>
            <td class="center">12</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[11].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[11].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[11].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[11].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_k">减：营业外⽀出</td>
            <td class="center">13</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[12].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[12].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[12].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[12].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight">四、利润总额（亏损总额以“－”号填列）(9+10+11+12-13)</td>
            <td class="center">14</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[13].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[13].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[13].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[13].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="left_k">减：所得税</td>
            <td class="center">15</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[14].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[14].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[14].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[14].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight">五、净利润（净亏损以“－”号填列）(14-15)</td>
            <td class="center">16</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[15].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[15].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[15].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[15].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td colspan="2">1．出售、处置部⻔或被投资单位所得收益</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[16].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[16].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[16].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[16].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td colspan="2">2．⾃然灾害发⽣的损失</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[17].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[17].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[17].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[17].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td colspan="2">3．会计政策变更增加（或减少）利润总额</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[18].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[18].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[18].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[18].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td colspan="2">4、会计估计变更增加（或减少）利润总额</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[19].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[19].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[19].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[19].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td colspan="2">5、债务重组损失</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[20].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[20].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[20].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[20].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td colspan="2">6、其他</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr[21].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr2[21].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr3[21].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="attachArr4[21].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
        </table>
      </div>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'lrbYTable3',
  components: { },
  props: {
    title: String,
  },
  data() {
    return {
      attachArr: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      attachArr2:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      attachArr3:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      attachArr4:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(376)
  },
  methods: {
    changeList(){
    },
    getList() {
      gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod}).then(res=>{
        if(res.data.data.info.jd1 && res.data.data.info.jd1.length != 0){
          this.attachArr = res.data.data.info.jd1
        }
        if(res.data.data.info.jd2 && res.data.data.info.jd2.length != 0){
          this.attachArr2 = res.data.data.info.jd2
        }
        if(res.data.data.info.jd3 && res.data.data.info.jd3.length != 0){
          this.attachArr3 = res.data.data.info.jd3
        }
        if(res.data.data.info.jd4 && res.data.data.info.jd4.length != 0){
          this.attachArr4 = res.data.data.info.jd4
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
    padding-right:20px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    // position: relative;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      // position: absolute;
      // bottom: 0;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
