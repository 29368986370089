<template>
  <div class="app-container">
    <div class="right_btns">
      <qzf-video vid="4467be5374b10cc543190b3e6adaf19d_4"></qzf-video>

      <qzf-button button_code="kjbb_sb" jz="false" v-if="!this.startAccountPeriod" type="primary" @success="shenbao" size="small" v-loading.fullscreen.lock="fullscreenLoading"   >
        <el-icon><List /></el-icon> <span  >申报</span>
      </qzf-button>
      <qzf-button button_code="kjbb_scbb" v-if="!this.startAccountPeriod" type="primary" @success="saveBiao" size="small" :loading="loading"   >
        <el-icon><HelpFilled /></el-icon> <span  >取数</span>
      </qzf-button>
      <qzf-button  jz="false" v-if="!this.startAccountPeriod" size="small" @success="printing()" type="success"   >
        <el-icon><Printer /></el-icon> <span  >打印</span>
      </qzf-button>
      <qzf-button jz="false" v-if="!this.startAccountPeriod" size="small" @success="downLoad()" type="primary"   >
        <el-icon><FolderOpened /></el-icon> <span  >导出</span>
      </qzf-button>
      <settings v-if="!this.startAccountPeriod && $buttonStatus('fp_jx_yjscpz')" name="设置" :type="['code_zcfz_cfl']"></settings>
    </div>
    <!-- 小企业 -->
    <div v-if="type == '小企业会计准则2013版'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb' && this.tableName != 'kj_xjllb'">
          <zcfzTable ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTable>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_xjllb'">
          <lrbTable title="利润表(季报)" typeName='会计报表' ref="lrbTable" :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTable>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTable title="利润表(月报)" typeName='会计报表' ref="lrbTableMon" :startAccountPeriod="startAccountPeriod"></lrbTable>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <!-- <lrbYTable title="利润统计表" ref="lrbYTable" :startAccountPeriod="startAccountPeriod"></lrbYTable> -->
          <lrbYTable title="利润统计表" typeName='会计报表' ref="lrbYear" :startAccountPeriod="startAccountPeriod"></lrbYTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashTable title="现金流量表(月报)" typeName='会计报表' ref="cashMon" :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashTable title="现金流量表(季报)" typeName='会计报表' ref="cash" :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 未执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableTwo ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableTwo title="利润表(季报)" ref="lrbTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableTwo title="利润表(月报)" ref="lrbTableMon"  typeName='会计报表' :startAccountPeriod="startAccountPeriod"></lrbTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableTwo ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod"></lrbYTableTwo>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <!-- <cashTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
          <cashQuarterTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
          <!-- <cashTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 企业会计制度 -->
    <div v-if="type == '企业会计制度'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableThree ref="zcfzTable" :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableThree>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableThree title="利润表(季报)" ref="lrbTable" :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableThree>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableThree title="利润表(月报)" ref="lrbTableMon" :startAccountPeriod="startAccountPeriod"></lrbTableThree>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableThree title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod"></lrbYTableThree>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 已执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则(已执行新金融准则)'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'" >
          <zcfzTableFour ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2" v-if="this.tableName != 'kj_zcfz'&& this.tableName != 'kj_xjllb'">
          <lrbTableFour title="利润表(季报)" ref="lrbTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbTableFour title="利润表(月报)" ref="lrbTableMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod"></lrbTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableFour title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod"></lrbYTableFour>
        </el-tab-pane>
        <el-tab-pane label="现金流量表(月报)" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表(月报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
          <!-- <cashTable title="现金流量表(月报)" ref="cashMon" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
        </el-tab-pane>
        <el-tab-pane label="现金流量表(季报)" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
          <!-- <cashTable title="现金流量表(季报)" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable> -->
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 民办非会计报表 -->
    <div v-if="type == '民办非'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1">
          <balanceBusiness ref="zcfzTable"></balanceBusiness>
        </el-tab-pane>
        <el-tab-pane label="业务活动表(月报)" name="3">
          <businessSheet ref="lrbTableMon"></businessSheet>
        </el-tab-pane>
        <el-tab-pane label="业务活动表(季报)" name="2">
          <businessSeason ref="lrbTable"></businessSeason>
        </el-tab-pane>
        
      </el-tabs>
    </div>
  </div>
</template>

<script>
// 小企业
import zcfzTable from "./tables/zcfzTable.vue"
import lrbTable from "./tables/lrbTable.vue"
import lrbYTable from "./tables/lrbYTable.vue"

// 未执行新金融准则
import zcfzTableTwo from "./tables/zcfzTable2.vue"
import lrbTableTwo from "./tables/lrbTable2.vue"
import lrbYTableTwo from "./tables/lrbYTable2.vue"

// 企业会计制度
import zcfzTableThree from "./tables/zcfzTable3.vue"
import lrbTableThree from "./tables/lrbTable3.vue"
import lrbYTableThree from "./tables/lrbYTable3.vue"

// 已执行新金融准则
import zcfzTableFour from "./tables/zcfzTable4.vue"
import lrbTableFour from "./tables/lrbTable4.vue"
import lrbYTableFour from "./tables/lrbYTable4.vue"

// 民办非
import balanceBusiness from './tables/balanceBusiness' //民办非资产负债
import businessSheet from './tables/businessSheet' //民办非业务活动表（月报）
import businessSeason from './tables/businessSeason' //民办非业务活动表（季报）

// 现金流量表
import cashTable from "./tables/cashTable.vue"
import cashQuarterTable from "./tables/cashQuarterTable"

import { report,exportReport } from "@/api/printSet"
import { createKj } from '@/api/taxCalculation.js'
export default {
  name:'finance',
  components:{
    zcfzTable,
    lrbTable,
    lrbYTable,
    cashTable,
    cashQuarterTable,

    zcfzTableTwo,
    lrbTableTwo,
    lrbYTableTwo,

    zcfzTableThree,
    lrbTableThree,
    lrbYTableThree,

    zcfzTableFour,
    lrbTableFour,
    lrbYTableFour,

    balanceBusiness,
    businessSheet,
    businessSeason,
    
  },
  data() {
    return {
      type:this.$store.getters["user/comInfo"].comKj,
      activeName:'1',
      comId:0,
      listQuery:{
        zcfz:1,
        lrb:1,
        xjllb:1
      },
      loading:false,
      startAccountPeriod:'',
      tableName:'',
      bookNames:'',
      mqy:''
    }
  },
  mounted() {
    if(this.$store.getters["commons/params"].tableName == 'kj_lrb'){
      this.$refs.lrbTable.getList()
    }else if(this.$store.getters["commons/params"].tableName == 'kj_xjllb'){
      this.$refs.cashMon.getList()
    }else{
      this.$refs.zcfzTable.getList()
    }
  },
  deactivated(){
    if(!this.$getCachedViews("finance")){//销毁 mitt bus
      this.$bus.off("financeUpdate")
    }
  },
  created(){
    if(this.$store.getters["commons/params"].type){
      this.type = this.$store.getters["commons/params"].type
      this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
      this.comId = this.$store.getters["commons/params"].comId
      this.tableName = this.$store.getters["commons/params"].tableName
    }else{
      this.type = this.$store.getters["user/comInfo"].comKj
    }
    if(this.$store.getters["commons/params"].tableName == 'kj_lrb'){
      this.activeName = '2'
    }else if(this.$store.getters["commons/params"].tableName == 'kj_xjllb'){
      this.activeName = '5'
    }
    this.initBus()

  },
  methods:{
    initBus(){
      this.$bus.on("financeUpdate", (val) => {
        this.saveBiao()
      });
    },
    saveBiao(){
      this.loading = true
      createKj(this.listQuery).then(res=>{
        if(res.data.data.subjectStatus == true){
          this.$notify.error({
            title: '提示',
            message: '银行现金金额小于0',
            duration: 0
          });
        }
        if(res.data.data.zcLessThen0 == true){
          this.$notify.error({
            title: '提示',
            message: '银行现金金额小于0',
            duration: 0
          });
        }
        if(res.data.data.zcfzStatus == true){
          this.$notify.error({
            title: '提示',
            message: '资产负债不平衡',
            duration: 0
          });
        }
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.$refs.zcfzTable.getList()
          this.$refs.lrbTable.getList()
          this.$refs.lrbTableMon.getList()
          this.$refs.lrbYear.getList()
          this.$refs.cashMon.getList()
          this.$refs.cash.getList()
        }
      })
    },
    handleClick(tab){
      if(tab.props.label == '资产负债表'){
        this.$refs.zcfzTable.getList()
      }else if(tab.props.label == '利润表(季报)' || tab.props.label == '业务活动表(季报)'){
        this.$refs.lrbTable.getList()
      } else if(tab.props.label == '利润表(月报)' || tab.props.label == '业务活动表(月报)'){
        this.$refs.lrbTableMon.getList()
      }else if(tab.props.label == '利润统计表'){
        this.$refs.lrbYear.getList()
      }else if(tab.props.label == '现金流量表(月报)'){
        this.$refs.cashMon.getList()
      }else if(tab.props.label == '现金流量表(季报)'){
        this.$refs.cash.getList()
      }
    },
    printing(){
      console.log(this.activeName);
      if(this.activeName == '1'){
        this.bookNames = 'kj_zcfz'
      }else if(this.activeName == '2'){
        this.bookNames = 'kj_lrb'
        this.mqy = 'q'
      }else if(this.activeName == '3'){
        this.bookNames = 'kj_lrb'
        this.mqy = 'm'
      }else if(this.activeName == '4'){
        this.bookNames = 'kj_lrb'
        this.mqy = 'y'
      }else if(this.activeName == '5'){
        this.bookNames = 'kj_xjllb'
        this.mqy = 'm'
      }else if(this.activeName == '6'){
        this.bookNames = 'kj_xjllb'
        this.mqy = 'q'
      }
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:[this.bookNames],
        mqy:this.mqy
      }
      report(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    downLoad(){
      if(this.activeName == '1'){
        this.bookNames = 'gs_cwbb'
      }else if(this.activeName == '2'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'q'
      }else if(this.activeName == '3'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'm'
      }else if(this.activeName == '4'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'y'
      }else if(this.activeName == '5'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'm'
      }else if(this.activeName == '6'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'q'
      }
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:[this.bookNames],
        mqy:this.mqy
      }
      exportReport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .right_btns{
    position: absolute;
    top: 16px;
    right: 40px;
    z-index: 99;
  }
</style>