<template>
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h5>{{title}}</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:31%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:13%;">第一季度</td>
          <td class="center" style="width:13%;">第二季度</td>
          <td class="center" style="width:13%;">第三季度</td>
          <td class="center" style="width:13%;">第四季度</td>
          <!-- <td class="center" style="width:13%;">本年累计</td> -->
        </tr>
      </table>
      <div class="fixed_box_table" :style="{height:contentStyleObj}">
        <table class="content" cellspacing="0">
          <tr>
            <td class="weight" style="width:31%;">一、营业收入</td>
            <td class="center" style="width:4%;">1</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[0].periodTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[0].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[0].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[0].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" v-model:amount="yaerTotal[0].periodTotal" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>减：营业成本</td>
            <td class="center">2</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[1].periodTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[1].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[1].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[1].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" v-model:amount="yaerTotal[1].periodTotal" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>税金及附加</td>
            <td class="center">3</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[2].periodTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[2].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[2].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[2].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" v-model:amount="yaerTotal[2].periodTotal" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>其中：消费税</td>
            <td class="center">4</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[3].periodTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[3].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[3].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[3].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" v-model:amount="yaerTotal[3].periodTotal" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>营业税</td>
            <td class="center">5</td>
            <qzf-td style="width:13%;" v-model:amount="jd1[4].periodTotal" :disabled="false" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd2[4].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd3[4].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" v-model:amount="jd4[4].periodTotal" :disabled="false"  @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" v-model:amount="yaerTotal[4].periodTotal" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>

          <tr>
            <td>城市维护建设税</td>
            <td class="center">6</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[5].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[5].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[5].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[5].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[5].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>

          <tr>
            <td>资源税</td>
            <td class="center">7</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[6].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[6].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[6].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[6].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[6].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>土地增值税</td>
            <td class="center">8</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[7].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[7].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[7].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[7].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[7].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>城镇土地使用税、房产税、车船税、印花税</td>
            <td class="center">9</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[8].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[8].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[8].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[8].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[8].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>教育附加、矿产资源、排污费</td>
            <td class="center">10</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[9].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[9].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[9].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[9].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[9].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>销售费用</td>
            <td class="center">11</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[10].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[10].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[10].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[10].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[10].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>其中：商品维修费</td>
            <td class="center">12</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[11].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[11].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[11].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[11].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[11].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>广告费和业务宣传费</td>
            <td class="center">13</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[12].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[12].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[12].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[12].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[12].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>管理费用</td>
            <td class="center">14</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[13].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[13].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[13].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[13].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[13].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>其中：开办费</td>
            <td class="center">15</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[14].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[14].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[14].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[14].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[14].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>业务招待费</td>
            <td class="center">16</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[15].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[15].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[15].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[15].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[15].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>研究费用</td>
            <td class="center">17</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[16].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[16].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[16].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[16].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[16].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>财务费用</td>
            <td class="center">18</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[17].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[17].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[17].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[17].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[17].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>其中：利息费用(收入以-号填列)</td>
            <td class="center">19</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[18].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[18].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[18].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[18].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[18].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>加：投资收益</td>
            <td class="center">20</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[19].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[19].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[19].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[19].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[19].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight">二、营业利润</td>
            <td class="center">21</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[20].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="jd2[20].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="jd3[20].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true"  v-model:amount="jd4[20].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="true"  v-model:amount="yaerTotal[20].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>加：营业外收入</td>
            <td class="center">22</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[21].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[21].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[21].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[21].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[21].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>其中：政府补助</td>
            <td class="center">23</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[22].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[22].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[22].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[22].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[22].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>减：营业外支出</td>
            <td class="center">24</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[23].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[23].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[23].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[23].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[23].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>其中：坏账损失</td>
            <td class="center">25</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[24].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[24].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[24].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[24].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[24].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>无法收回的长期债券投资损失</td>
            <td class="center">26</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[25].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[25].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[25].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[25].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[25].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>无法收回的长期股权投资损失</td>
            <td class="center">27</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[26].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[26].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[26].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[26].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[26].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>自然灾害等不可抗力因素造成的损失</td>
            <td class="center">28</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[27].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[27].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[27].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[27].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[27].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>税收滞纳金</td>
            <td class="center">29</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[28].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd2[28].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd3[28].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false"  v-model:amount="jd4[28].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[28].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight">三、利润总额</td>
            <td class="center">30</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[29].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[29].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[29].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[29].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="true"  v-model:amount="yaerTotal[29].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td>减：所得税费用</td>
            <td class="center">31</td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd1[30].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd2[30].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd3[30].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="false" v-model:amount="jd4[30].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="false"  v-model:amount="yaerTotal[30].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
          <tr>
            <td class="weight">四、净利润</td>
            <td class="center">32</td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd1[31].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd2[31].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd3[31].periodTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:13%;" :disabled="true" v-model:amount="jd4[31].periodTotal" @change="changeList()"></qzf-td>
            <!-- <qzf-td style="width:13%;" :disabled="true"  v-model:amount="yaerTotal[31].periodTotal" @change="changeList()"></qzf-td> -->
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'lrbYTable',
  props:{
    title:String,
  },
  data() {
    return {
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      jd1: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd2:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd3:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      jd4:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      yaerTotal:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化

    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(400)
  },
  methods: {
    changeList(){
      
    },
    getList() {
      gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod}).then(res=>{
        if(res.data.data.info.jd1 && res.data.data.info.jd1.length != 0){
          this.jd1 = res.data.data.info.jd1
        }
        if(res.data.data.info.jd2 && res.data.data.info.jd2.length != 0){
          this.jd2 = res.data.data.info.jd2
        }
        if(res.data.data.info.jd3 && res.data.data.info.jd3.length != 0){
          this.jd3 = res.data.data.info.jd3
        }
        if(res.data.data.info.jd4 && res.data.data.info.jd4.length != 0){
          this.jd4 = res.data.data.info.jd4
        }
        // 本年累计计算公式 todo
      })
      // if(this.initType == '初始化财务'){
      //   gsInfo({tableName: 'kj_lrb',itemName:'year',period:this.startAccountPeriod}).then(res=>{
      //     if(res.data.data.info){
      //       this.list = res.data.data.info
      //     }
      //   })
      // }else{
      //   gsInfo({tableName: 'kj_lrb',itemName:'year'}).then(res=>{
      //     if(res.data.data.info){
      //       this.list = res.data.data.info
      //     }
      //   })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 3px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
</style>
